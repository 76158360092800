<script setup lang="ts">
import { usePreferredDark } from "@vueuse/core"
import { useTheme } from "~/composables/useTheme"
import { useCookie } from "#imports"

const useDark = useTheme()
const themeCookie = useCookie("theme", {
    maxAge: 365 * 24 * 60 * 60,
})

if (!themeCookie.value) {
    themeCookie.value = usePreferredDark() ? "dark" : "light"
    useDark.value = themeCookie.value!
} else {
    useDark.value = themeCookie.value!
    themeCookie.value = useDark.value
}

function toggleDark() {
    if (useDark.value === "light") {
        useDark.value = "dark"
        themeCookie.value = "dark"
    } else {
        useDark.value = "light"
        themeCookie.value = "light"
    }
}
</script>

<template>
    <n-switch :on-update:value="toggleDark" :value="useDark === 'dark'" class="mr-4">
        <template #checked-icon>
            <Icon name="ri:moon-line" class="text-gray-400" />
        </template>
        <template #unchecked-icon>
            <Icon name="ri:sun-line" class="text-gray-400" />
        </template>
    </n-switch>
</template>
